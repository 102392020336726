/* You can add global styles to this file, and also import other style files */

html, body { 
    height: 100%;
    margin: 10px;
    background-color: var(--blue-50);

}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.spacer {
    flex: 1 1 auto;
}
.nav-bar{
    position: sticky; top:0; left:0; z-index: 999;
}
